/* eslint  jsx-a11y/img-redundant-alt: 0 */

import React from 'react'
import '../assets/home.scss'
import LogoVertical from '../images/PictureNorth_Logo_Vert.svg'

import Layout from '../components/layout'
import vidPoster from '../images/Home_Page_Social_Media_Thumb_02.jpg'


const vidReadyHandler = (e) => {
  let vid = document.getElementById('vid-bg')
  let logo = document.getElementById('home-logo')

  if (vid.readyState >= vid.HAVE_FUTURE_DATA) {
    vid.style.opacity = 1
    logo.classList.add('hide')
  
  } else {
      vid.addEventListener('canplay', function () {
        vid.style.opacity = 1
        logo.classList.add('hide')
      
      }, false);
  }
  
  vid.style.opacity = 1
  logo.classList.add('hide')
}

const IndexPage = (data) => (
  <Layout
    data
  >
    
    <div className='logo-wrap'>
      <img id='home-logo' alt="Picture North Video Production" src={LogoVertical} />
    </div>
    <video onCanPlay={(e) => vidReadyHandler()} poster={vidPoster} className='vid-bg' id='vid-bg' autoPlay muted loop style={{backgroundImage:  `url(${vidPoster})`}} data-wf-ignore="true"><source src="https://player.vimeo.com/progressive_redirect/playback/570110640/rendition/1080p/file.mp4?loc=external&signature=e80a0acea18fbe9c7e286875ba9c2e13307b7f709c5220bf1b39ec285e595266" data-wf-ignore="true" /></video>
  </Layout>
)

export default IndexPage
